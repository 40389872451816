import { h, render } from 'preact';
import Button from '../components/Button';
import HourChart from '../components/HourChart';

document.addEventListener('DOMContentLoaded', () => {
  // Render Button component
  const buttonElement = document.querySelector('[data-preact-class="Button"]');
  if (buttonElement) {
    const buttonProps = JSON.parse(buttonElement.getAttribute('data-preact-props'));
    render(<Button {...buttonProps} />, buttonElement);
  }

  // Render HourChart component
  const hourChartElement = document.querySelector('[data-preact-class="HourChart"]');
  if (hourChartElement) {
    const hourChartProps = JSON.parse(hourChartElement.getAttribute('data-preact-props'));
    console.log('HourChart element found:', hourChartElement);
    console.log('HourChart props:', hourChartProps);
    render(<HourChart {...hourChartProps} />, hourChartElement);
  } else {
    console.log('HourChart element not found');
  }
});