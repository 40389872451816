import { h, Component } from 'preact';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

class HourChart extends Component {
  componentDidMount() {
    const ctx = this.canvas.getContext('2d');
    new Chart(ctx, {
      type: 'line',
      data: {
        labels: this.props.days, // ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']
        datasets: [{
          label: 'Heures travaillées',
          data: this.props.hours, // [8, 7, 8, 6, 7]
          backgroundColor: 'rgba(0, 123, 255, 0.5)',
          borderColor: 'rgba(0, 123, 255, 1)',
          borderWidth: 1,
          fill: false
        }]
      },
      options: {
        scales: {
          x: {
            title: {
              display: true,
              text: 'Jours'
            }
          },
          y: {
            title: {
              display: true,
              text: 'Heures'
            },
            beginAtZero: true
          }
        }
      }
    });
  }

  render() {
    return (
      <canvas ref={canvas => this.canvas = canvas} width="400" height="200"></canvas>
    );
  }
}

export default HourChart;