// app/javascript/components/Button.js
import { h, Component } from "preact";

class Button extends Component {
  render(props, state) {
    const buttonStyle = {
      backgroundColor: '#007bff',
      color: 'white',
      border: 'none',
      padding: '10px 20px',
      fontSize: '10px',
      cursor: 'pointer'
    };

    return (
      <button style={buttonStyle} type={props.type}>{props.label}</button>
    );
  }
}

export default Button;